
.Mouse{
  width: 28px;
  height: 40px;
  border: 2px solid white;
  border-radius: 20px;
}

.Wheel{
  width:0px;
  border: 2px solid white;
  height:6px;
  border-radius:2px;
  position: relative;
  margin: auto;
  animation-name: scroll;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-direction: alternate;
}

.Hand {
  animation-name: scroll;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-direction: alternate;
}

@keyframes scroll {
  0%    { transform: translate(0, 8px); opacity: 0 } 
  30%   { opacity: 1;}
  100%  { transform: translate(0, 16px); opacity: 1}
}