.holo {
  background: linear-gradient(45deg, var(--holo-long));
  animation: gradient 5s ease infinite;
  background-size: 600% 600%;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
  50% {
		background-position: 50% 0%;
	}
	100% {
		background-position: 0% 50%;
	}
}