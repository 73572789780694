
.holo {
  background: var(--holo-bg);
	animation: gradient 5s ease infinite;
  background-size: 400% 400%;
}

@media (max-width: 768px) {
		.holo {
			animation: none;
		}
	}


.dotsCarousel {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 2em;
}

.dotsCarousel button{
    height: 12px;
    width: 12px;
    margin: 3px;
    border-radius: 50%;
    border: 1px solid white;
}

.dotsCarouselDark button{
    border: 1px solid black;
}

.dotsCarouselDark button {
  border: 1px solid wihte;
}

.dotsCarousel button[class*="--selected"]{
    background: white;
}

.dotsCarouselDark button[class*="--selected"]{
    background: black;
}



@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
  50% {
		background-position: 50% 0%;
	}
	100% {
		background-position: 0% 50%;
	}
}